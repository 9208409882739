import { default as _91_46_46_46slug_93Qv3uKeTLMXMeta } from "/vercel/path0/pages/[...slug].vue?macro=true";
import { default as component_45stubAdZrdggQSxMeta } from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@types+node@20.14.15_eslint@8.57.0_rollup@4.20.0_sass@1.77.2_stylelint@14.16.1_ty_zumdled7skmvuz2bz25z37qfyu/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubAdZrdggQSx } from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@types+node@20.14.15_eslint@8.57.0_rollup@4.20.0_sass@1.77.2_stylelint@14.16.1_ty_zumdled7skmvuz2bz25z37qfyu/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug___de-ch",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93Qv3uKeTLMXMeta || {},
    component: () => import("/vercel/path0/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: component_45stubAdZrdggQSxMeta?.name,
    path: "/home",
    component: component_45stubAdZrdggQSx
  },
  {
    name: component_45stubAdZrdggQSxMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubAdZrdggQSx
  }
]