/*
    Checks if a given link is internal or not
    @param url (String) the url you want to check
*/
export const isInternalLink = (url) => {
    if (!url) {
        throw createError({
            statusCode: '500',
            statusMessage: 'Fatal: isInternalLink function expected url string @useHelpers',
        });
    }

    const runtimeConfig = useRuntimeConfig();
    const base = new URL(runtimeConfig.public.BASE_URL);
    return new URL(url, base).hostname === base.hostname && !url.includes('@');
};

export const urlExists = (url) => {
    const http = new XMLHttpRequest();
    http.open('HEAD', url, true);
    http.send();
    if (http.status !== 404) return true;
    return false;
};

export const slugify = (str) => str?.toLowerCase().trim().replace(/[^\w\s-]/g, '').replace(/[\s_-]+/g, '-')
    ?.replace(/^-+|-+$/g, '');

export const removeGetParams = (str) => {
    const splitted = str.split('?');
    return splitted[0];
};

export const deepObjectLog = (obj) => {
    // eslint-disable-next-line no-console
    console.log(JSON.stringify({
        obj,
    }, null, 2));
};
export const toCamelCase = (str, seperator = '_') => {
    const splitted = str.split(seperator);
    const first = splitted[0];
    const rest = splitted.slice(1).map((word) => word.charAt(0).toUpperCase() + word.slice(1));

    return [first, ...rest].join('');
};
// eslint-disable-next-line no-promise-executor-return
export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, Math.ceil(ms)));

/* Returns grouped arrays by property */
export const groupArrayByProperty = (arr, property) => arr.reduce((acc, cur) => {
    acc[cur[property]] = [...acc[cur[property]] || [], cur];

    return acc;
}, {});

export const round = (value, step = 0.05) => {
    const inv = 1.0 / step;
    return Math.round(value * inv) / inv;
};

export const mapOrder = (array, order, key, key2) => {
    array.sort((a, b) => {
        const A = a[key][key2];
        const B = b[key][key2];

        if (order.indexOf(A) > order.indexOf(B)) {
            return 1;
        }
        return -1;
    });

    return array;
};

export const getImageHeight = (
    originalWidth,
    targetWidth,
    originalHeight,
) => parseInt((originalHeight / originalWidth) * targetWidth, 10);

export const parseHTMLString = (text) => text?.replace(/&amp;/g, '&')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&quot;/g, '"')
    .replace(/&apos;/g, "'")
    .replace(/&uuml;/g, 'ü')
    .replace(/&auml;/g, 'ä')
    .replace(/&ouml;/g, 'ö')
    .replace(/&Uuml;/g, 'Ü')
    .replace(/&Auml;/g, 'Ä')
    .replace(/&Ouml;/g, 'Ö')
    .replace(/&szlig;/g, 'ß')
    .replace(/<[^>]*>?/gm, '') || '';

export const sanitize = (string) => string.replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#x27;')
    .replace(/\//g, '&#x2F;');

export const toUpperCamelCase = (str) => str
    .replace(/[-_](.)/g, (_, c) => c.toUpperCase())
    .replace(/^(.)/, (_, c) => c.toUpperCase());

export const getRandomColorHex = () => {
    const randomInt = Math.floor(Math.random() * 16777215);
    const hexString = randomInt.toString(16);
    return `#${hexString.padStart(6, '0')}`;
};

export const degreesToRadians = (degrees) => degrees * (Math.PI / 180);
