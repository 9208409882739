<template>
  <component
    :is="htmlTag"
    class="atom-button"
    :type="buttonType"
    :class="{ 'is-hidden': isHidden }"
    @click="onClick"
  >
    <span
      class="atom-button__text"
    >{{ text }}</span>
  </component>
</template>

<script setup>
const props = defineProps({
    /*
          Text
      */
    text: {
        type: String,
        required: true,
    },

    /*
          Styles
      */
    backgroundColor: {
        type: String,
        default: 'var(--c-primary)',
    },

    textColor: {
        type: String,
        default: 'var(--c-white)',
    },

    effectColor: {
        type: String,
        default: 'var(--c-secondary)',
    },

    borderRadius: {
        type: String,
        default: 'var(--b-radius--big)',
    },

    /*
          States
    */
    isDisabled: {
        type: Boolean,
        default: false,
        validator: (value) => typeof value === 'boolean',
    },

    /*
          Functionality
    */
    callback: {
        type: Function,
        default: () => {},
    },

    htmlTag: {
        type: String,
        default: 'button',
        validator: (value) => [
            'button',
            'div',
        ].includes(value),
    },

    buttonType: {
        type: String,
        default: 'button',
        validator: (value) => [
            'button',
            'submit',
            'reset',
        ].includes(value),
    },

    isHidden: {
        type: Boolean,
        default: false,
        validator: (value) => typeof value === 'boolean',
    },
});

const onClick = () => {
    if (props.isDisabled) return;
    props.callback();
};

const {
    textColor,
    backgroundColor,
    borderRadius,
    effectColor,
} = toRefs(props);
</script>

<style lang="scss" scoped>
.atom-button__text {
    z-index: 1;
}

.atom-button__icon {
    z-index: 1;
    transition: transform 0.3s var(--ease-in-out--sine);
}

.atom-button {
    position: relative;
    display: flex;
    align-items: center;
    align-self: flex-start;
    padding: 14px 25px;
    border-radius: v-bind(borderRadius);
    -webkit-appearance: none;
    background-color: v-bind(backgroundColor);
    color: v-bind(textColor);
    column-gap: 20px;
    font-family: var(--f-family--primary);
    font-size: var(--f-size--a);
    font-weight: var(--f-weight--900);
    line-height: var(--l-height--a);
    outline: none;

    /*
        Icon
    */
    &.is-icon-left {
        .atom-button__icon {
            order: -1;
        }
    }

    /*
        States
    */
    &:after {
        position: absolute;
        z-index: -1;
        bottom: 6px;
        left: 0;
        width: 100%;
        height: 15px;
        background: v-bind(effectColor);
        border-bottom-left-radius: v-bind(borderRadius);
        border-bottom-right-radius: v-bind(borderRadius);
        content: '';
        transform: translateY(100%);
        transition: transform 0.6s var(--ease-out--quint);
    }

    &:hover {
        &:after {
            transform: translateY(0%);
        }
    }

    // &:disabled {
    //     cursor: not-allowed;
    // }

    &.is-hidden {
        opacity: 0;
        pointer-events: none;
    }
}
</style>
