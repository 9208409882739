<template>
  <transition :name="isLocking ? 'fade-in' : 'slide-up'">
    <div
      v-if="showBanner"
      class="block-overlay-cookie"
      :class="{ 'is-locking': isLocking }"
    >
      <div class="block-overlay-cookie__inner">
        <div class="block-overlay-cookie__texts">
          <div v-if="title" class="block-overlay-cookie__title">
            <AtomTextHeading html-tag="div" font-size="h4" :text="title" />
          </div>

          <AtomTextRichtext :html="text" />
          <div class="block-overlay-cookie__links">
            <UtilRouteLink
              v-for="(link, index) in links"
              :key="`cookie-banner-link-${index}`"
              :text="link.text"
              :href="link.href"
            />
          </div>
        </div>

        <div class="block-overlay-cookie__buttons">
          <button
            class="block-overlay-cookie__button"
            type="button"
            @click="setUsersChoice('accept-all')"
          >
            {{ acceptAll }}
          </button>
          <button
            class="block-overlay-cookie__button"
            type="button"
            @click="setUsersChoice('accept-essential')"
          >
            {{ acceptEssential }}
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup>
const props = defineProps({
    isOpen: {
        type: Boolean,
        default: false,
    },

    isLocking: {
        type: Boolean,
        default: false,
    },

    title: {
        type: String,
        default: 'Cookie Consent',
    },

    text: {
        type: String,
        default: 'This website uses cookies to ensure you get the best experience on our website.',
    },

    links: {
        type: Array,
        default: () => [],
    },

    acceptAll: {
        type: String,
        default: 'Accept all',
    },

    acceptEssential: {
        type: String,
        default: 'Accept essential',
    },

    gtmIds: {
        type: Array,
        default: null,
    },
});

/*
    Check for existing cookies and
    start tracking or show banner
*/
const showBanner = ref(props.isOpen);
const acceptedAll = useCookie('cookies-accepted-all');
const acceptedRequired = useCookie('cookies-accepted-required');

onMounted(() => {
    if (!acceptedAll.value && !acceptedRequired.value) {
        showBanner.value = true;
    }

    if (acceptedAll.value) {
        initGtm(props.gtmIds);
    }
});
/*
set cookies and hide banner and start tracking (if allowed)
*/
const setUsersChoice = (action) => {
    showBanner.value = false;

    switch (action) {
    case 'accept-all':
        acceptedAll.value = true;
        acceptedRequired.value = true;
        initGtm(props.gtmIds);
        return;
    case 'accept-essential':
        acceptedRequired.value = true;
        break;
    default:
        break;
    }
};

</script>

<style lang="scss" scoped>
.block-overlay-cookie {
    @include z-index('cookie');
    position: fixed;
    right: var(--page-padding);
    bottom: var(--page-padding);
    display: flex;
    width: calc(100vw - (var(--page-padding) * 2));
    max-width: 330px;
    font-family: var(--f-family--primary);

    &.is-locking{
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        max-width: unset;
        height: 100dvh;
        align-items: center;
        justify-content: center;
        backdrop-filter: blur(5px);
        background: rgba(255, 255, 255, 0.75);
    }
}

.block-overlay-cookie__links {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    color: var(--c-primary);
    gap: 10px;
    text-decoration: underline;
}

.block-overlay-cookie__inner {
    overflow: hidden;
    border-radius: var(--b-radius--bigger);
    background: var(--c-white);
    box-shadow: var(--box-shadow--big);
}

.block-overlay-cookie__texts {
    @include fluid-simple('padding', 24px, 28px);
    width: calc(100vw - (var(--page-padding) * 2));
    max-width: 330px;
}

.block-overlay-cookie__title {
    margin-bottom: 10px;
}

.block-overlay-cookie__buttons {
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid var(--c-primary--light);
}

.block-overlay-cookie__button {
    @include fluid-simple('height', 50px, 60px);

    display: flex;
    width: 50%;
    align-items: center;
    justify-content: center;
    background: var(--c-primary);
    color: var(--c-white);
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;

    &:last-child {
        background-color: var(--c-white);
        color: var(--c-primary);
    }
}
</style>
