import * as Sentry from '@sentry/vue';

export default defineNuxtPlugin((nuxtApp) => {
    const router = useRouter();
    const { public: { IS_STORYBOOK, sentry } } = useRuntimeConfig();

    if (IS_STORYBOOK === 'true' || IS_STORYBOOK === true || !sentry.DSN || sentry.ENABLED !== true) {
        // eslint-disable-next-line no-console
        console.table({
            IS_STORYBOOK,
            sentryDSN: sentry.DSN,
            sentryEnabled: sentry.ENABLED,
        });
        return null;
    }
    const { vueApp } = nuxtApp;

    Sentry.init({
        app: vueApp,
        dsn: sentry.DSN,
        environment: sentry.ENVIRONMENT,
        integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            }),
        ],

        beforeSend(event) {
            return event;
        },

        tracesSampleRate: 0.2, // Change in prod
        replaysSessionSampleRate: 1.0, // Change in prod
        replaysOnErrorSampleRate: 1.0, // Change in prod if necessary
    });

    vueApp.mixin(Sentry.createTracingMixins({ trackComponents: true, timeout: 2000, hooks: ['activate', 'mount', 'update'] }));
    Sentry.attachErrorHandler(vueApp, {
        logErrors: false, attachProps: true, trackComponents: true, timeout: 2000, hooks: ['activate', 'mount', 'update'],
    });

    return {
        provide: {
            sentrySetContext: Sentry.setContext,
            sentrySetUser: Sentry.setUser,
            sentrySetTag: Sentry.setTag,
            sentryAddBreadcrumb: Sentry.addBreadcrumb,
            sentryCaptureException: Sentry.captureException,
            sentryCaptureMessage: Sentry.captureMessage,
        },
    };
});
