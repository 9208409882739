<template>
  <div class="error-layout">
    <div class="error-layout__inner">
      <div class="error-layout__column">
        <div class=" error-layout__code">
          <AtomTextHeading
            html-tag="h1"
            font-size="h1"
            :text="error.statusCode"
            :pretitle="errorTitle"
          />
        </div>
      </div>

      <div class="error-layout__column">
        <div class="error-layout__content">
          <AtomTextRichtext
            :html="error.statusCode === 404 ? messageText
              : error.message"
          />

          <AtomButton
            type="button"
            class="button button--primary"
            :text="backText"
            @click="handleError()"
          >
            {{ backText }}
          </AtomButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>

defineProps({
    error: {
        type: Object,
        default: () => {},
    },
});

const { locale } = useI18n();
const localePath = useLocalePath();

const messageText = computed(() => {
    switch (locale.value) {
    case 'en':
        return 'Sorry, this page could not be found.';
    case 'fr-ch':
        return 'Désolé, cette page ne peut être trouvée.';
    case 'it-ch':
        return 'Spiacente, questa pagina non può essere trovata.';
    default:
        return 'Entschuldigen Sie, diese Seite kann nicht gefunden werden.';
    }
});

const errorTitle = computed(() => {
    switch (locale) {
    case 'en':
        return 'Error';
    case 'fr-ch':
        return 'Erreur';
    case 'it-ch':
        return 'Errore';
    default:
        return 'Fehler';
    }
});

const backText = computed(() => {
    switch (locale) {
    case 'en':
        return 'To the homepage';
    case 'fr-ch':
        return 'Retour à la page d\'accueil';
    case 'it-ch':
        return 'Alla pagina principale';
    default:
        return 'Zur Startseite';
    }
});

const handleError = () => {
    const redirectPath = localePath('/');
    clearError({ redirect: redirectPath });
};
</script>

<style scoped lang="scss">
.error-layout {
    @include wrapper-layout();
    display: flex;
    min-height: 100%;
    align-items: center;
    justify-content: center;
}

.error-layout__content {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
}
</style>
