import { defineNuxtPlugin } from '#app/nuxt'
import { LazySvgoArrowLeft, LazySvgoArrowRight, LazySvgoCamera, LazySvgoClose, LazySvgoLogo, LazySvgoPopupError, LazySvgoPopupSuccess, LazySvgoPopupWarning } from '#components'
const lazyGlobalComponents = [
  ["SvgoArrowLeft", LazySvgoArrowLeft],
["SvgoArrowRight", LazySvgoArrowRight],
["SvgoCamera", LazySvgoCamera],
["SvgoClose", LazySvgoClose],
["SvgoLogo", LazySvgoLogo],
["SvgoPopupError", LazySvgoPopupError],
["SvgoPopupSuccess", LazySvgoPopupSuccess],
["SvgoPopupWarning", LazySvgoPopupWarning],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
