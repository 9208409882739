<template>
  <NuxtLayout>
    <lenis
      @initiated="addLenis($event, 'global');"
    >
      <NuxtPage class="__nuxt-page" />
    </lenis>

    <BlockOverlayCookie
      v-if="isArrayNotEmpty(trackingData?.gtm_tags) && runtimeConfig.USE_GTM"
      :title="trackingData?.title"
      :is-locking="trackingData.is_fullscreen"
      :text="trackingData?.text"
      :accept-all="trackingData?.accept_all_text"
      :accept-essential="trackingData?.required_text"
      :gtm-ids="trackingData?.gtm_tags.map((tag) => tag.tag)"
      :links="[
        {
          text: trackingData?.policy_link_text,
          href: `/${trackingData?.policy_link?.cached_url}`,
        },
      ]"
    />
  </NuxtLayout>
</template>

<script setup>
const { locale, defaultLocale } = useI18n();

/*
  Dependencies
*/
const route = useRoute();
const storyblokApi = useStoryblokApi();
const { public: runtimeConfig } = useRuntimeConfig();
const { USE_STORYBLOK_DRAFT } = runtimeConfig;

/*
  Initialize variables
*/
const generalVersion = route.query._storyblok ? 'draft' : 'published';
const version = USE_STORYBLOK_DRAFT === 'true' ? 'draft' : generalVersion;
provide('storyblokVersion', version);
provide('isStoryblokPreview', generalVersion === 'draft');

/*
  Cookie Banner
*/
const trackingData = computed(() => getStoryblokSetting('tracking'));

/*
  Get settings from storyblok
*/
const { error } = await useAsyncData(
    'storyblok-settings-data',
    () => storyblokApi.get(
        'cdn/stories/',
        {
            version,
            language: locale.value === defaultLocale ? '' : locale.value,
            starts_with: 'settings/',
            resolve_links: 'link',
        },
    ),
    {
        watch: [locale],
    },
);

if (error.value) {
    throw createError({
        statusCode: '500',
        statusMessage: 'Whoops! Failed to fetch settings from storyblok @App.vue',
    });
}
</script>
