export default defineNuxtPlugin(() => {
    // nuxtApp
    const route = useRoute();
    const preview = route.query?._storyblok || false;

    if (preview) {
        // We need to check this later currently it breaks the 3d model in the storyblok preview
        // nuxtApp.hook('page:finish', () => { refreshNuxtData(); });
    }

    return { provide: { preview } };
});
