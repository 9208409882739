<template>
  <div
    class="atom-heading"
    :class="[
      `is-size-${fontSize}`,
      { 'is-on-grid': isOnGrid },
    ]"
  >
    <div v-if="pretitle" class="atom-heading__pretitle">
      {{ pretitle }}
    </div>

    <h1 v-if="htmlTag === 'h1'">
      {{ text }}
    </h1>

    <h2 v-else-if="htmlTag === 'h2'">
      {{ text }}
    </h2>

    <h3 v-else-if="htmlTag === 'h3'">
      {{ text }}
    </h3>

    <h4 v-else-if="htmlTag === 'h4'">
      {{ text }}
    </h4>

    <h5 v-else-if="htmlTag === 'h5'">
      {{ text }}
    </h5>

    <h6 v-else-if="htmlTag === 'h6'">
      {{ text }}
    </h6>

    <div v-else>
      {{ text }}
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
    htmlTag: {
        type: String,
        default: 'h2',
        validator: (value) => ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'div'].includes(value),
    },

    fontSize: {
        type: String,
        default: 'h2',
    },

    fontColor: {
        type: String,
        default: 'var(--c-primary)',
    },

    alignment: {
        type: String,
        default: 'left',
        validator: (value) => ['left', 'center', 'right'].includes(value),
    },

    text: {
        type: String,
        default: '',
    },

    pretitle: {
        type: String,
        default: '',
    },

    /*
      Layout
    */
    isOnGrid: {
        type: Boolean,
        default: false,
    },
});

const { fontColor, alignment } = toRefs(props);
</script>

<style lang="scss" scoped>
.atom-heading {

    /*
        Color schema
    */
    color: v-bind(fontColor);
    font-family: var(--f-family--primary);
    font-weight: var(--f-weight--900);
    text-align: v-bind(alignment);

    /*
        Font size
    */
    &.is-size-h1 {
        font-size: var(--f-size--h1);
        line-height: var(--l-height--h1);
    }

    &.is-size-h2 {
        font-size: var(--f-size--h2);
        line-height: var(--l-height--h2)
    }

    &.is-size-h3 {
        font-size: var(--f-size--h3);
        line-height: var(--l-height--h3)
    }

    &.is-on-grid {
        @include wrapper-layout;
        @include grid-layout();

        margin-bottom: var(--m-bottom--smallest);
        row-gap: 0;

        & > * {
            @include default-content-columns;
        }
    }
}

.atom-heading__pretitle {
    color: var(--c-black);
    font-family: var(--f-family--primary);
    font-size: var(--f-size--p);
    font-weight: var(--f-weight--400);
    line-height: var(--l-height--p);
}
</style>
