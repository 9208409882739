/* eslint-disable import/no-unresolved */
import { plugin, defaultResolvers } from '@marvr/storyblok-rich-text-vue-renderer';
import UtilRouteLink from '~/components/Util/Route/UtilRouteLink.vue';

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.use(
        plugin({
            resolvers: {
                ...defaultResolvers,
                link: UtilRouteLink,
            },
        }),
    );
});
