import revive_payload_client_T37rvJkkJb from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@types+node@20.14.15_eslint@8.57.0_rollup@4.20.0_sass@1.77.2_stylelint@14.16.1_ty_zumdled7skmvuz2bz25z37qfyu/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_LhWPUoXqgn from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@types+node@20.14.15_eslint@8.57.0_rollup@4.20.0_sass@1.77.2_stylelint@14.16.1_ty_zumdled7skmvuz2bz25z37qfyu/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_OL0P3Kildl from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@types+node@20.14.15_eslint@8.57.0_rollup@4.20.0_sass@1.77.2_stylelint@14.16.1_ty_zumdled7skmvuz2bz25z37qfyu/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_L9X2ev2l1K from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@2.2.15_rollup@4.20.0_vite@5.2.11_vue@3.4.37/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yRtfnnXcXo from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@types+node@20.14.15_eslint@8.57.0_rollup@4.20.0_sass@1.77.2_stylelint@14.16.1_ty_zumdled7skmvuz2bz25z37qfyu/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_INvKGuch8G from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@types+node@20.14.15_eslint@8.57.0_rollup@4.20.0_sass@1.77.2_stylelint@14.16.1_ty_zumdled7skmvuz2bz25z37qfyu/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_3LTxDxN0pF from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@types+node@20.14.15_eslint@8.57.0_rollup@4.20.0_sass@1.77.2_stylelint@14.16.1_ty_zumdled7skmvuz2bz25z37qfyu/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_nywAMe0chr from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@types+node@20.14.15_eslint@8.57.0_rollup@4.20.0_sass@1.77.2_stylelint@14.16.1_ty_zumdled7skmvuz2bz25z37qfyu/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_DTBjnD25I1 from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@types+node@20.14.15_eslint@8.57.0_rollup@4.20.0_sass@1.77.2_stylelint@14.16.1_ty_zumdled7skmvuz2bz25z37qfyu/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import strapi_iCuFIJeOiE from "/vercel/path0/node_modules/.pnpm/@nuxtjs+strapi@1.12.0_rollup@4.20.0/node_modules/@nuxtjs/strapi/dist/runtime/plugins/strapi.mjs";
import plugin_vRumCDW1ij from "/vercel/path0/node_modules/.pnpm/nuxt-lenis@1.2.4_react-dom@18.3.1_react@18.3.1_rollup@4.20.0/node_modules/nuxt-lenis/dist/runtime/plugin.mjs";
import plugin_Qa1h2am6Do from "/vercel/path0/node_modules/.pnpm/@storyblok+nuxt@6.0.10/node_modules/@storyblok/nuxt/dist/runtime/plugin.mjs";
import switch_locale_path_ssr_f4gtl4N964 from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.3.3_rollup@4.20.0_vue@3.4.37/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_q6ELgViU4R from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.3.3_rollup@4.20.0_vue@3.4.37/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import app_init_hybrid_5gOhL5QG1b from "/vercel/path0/plugins/app-init-hybrid.js";
import app_init_client_lDwNIL1JCF from "/vercel/path0/plugins/app-init.client.js";
import chunk_error_handler_wTHYgqOJwi from "/vercel/path0/plugins/chunk-error-handler.js";
import sb_preview_757msyeJ0g from "/vercel/path0/plugins/sb-preview.js";
import sentry_client_GoGQuZo4Et from "/vercel/path0/plugins/sentry.client.js";
import storyblok_J8xNkQ5W7C from "/vercel/path0/plugins/storyblok.js";
import vue_gtag_S6KjvYXIWb from "/vercel/path0/plugins/vue-gtag.js";
export default [
  revive_payload_client_T37rvJkkJb,
  unhead_LhWPUoXqgn,
  router_OL0P3Kildl,
  _0_siteConfig_L9X2ev2l1K,
  payload_client_yRtfnnXcXo,
  navigation_repaint_client_INvKGuch8G,
  check_outdated_build_client_3LTxDxN0pF,
  chunk_reload_client_nywAMe0chr,
  components_plugin_KR1HBZs4kY,
  prefetch_client_DTBjnD25I1,
  strapi_iCuFIJeOiE,
  plugin_vRumCDW1ij,
  plugin_Qa1h2am6Do,
  switch_locale_path_ssr_f4gtl4N964,
  i18n_q6ELgViU4R,
  app_init_hybrid_5gOhL5QG1b,
  app_init_client_lDwNIL1JCF,
  chunk_error_handler_wTHYgqOJwi,
  sb_preview_757msyeJ0g,
  sentry_client_GoGQuZo4Et,
  storyblok_J8xNkQ5W7C,
  vue_gtag_S6KjvYXIWb
]