<template>
  <NuxtLink
    v-if="!isStorybook && !isExternalLink"
    :to="replacedHref"
    :rel="target === '_blank' ? 'noopener noreferrer' : null"
    :aria-label="ariaLabel"
    target="_self"
    class="atom-link"
  >
    <slot />
  </NuxtLink>
  <a
    v-else
    :href="href"
    rel="noopener noreferrer"
    target="_blank"
    :aria-label="ariaLabel"
    class="atom-link"
  >
    <slot />
  </a>
</template>

<script setup>
const props = defineProps({
    href: {
        type: String,
        default: '',
        validator: (value) => value.length > 0,
    },

    target: {
        type: String,
        default: '_self',
        validator: (value) => ['_blank', '_self'].includes(value),
    },

    ariaLabel: {
        type: String,
        default: '',
    },
});

const isExternalLink = computed(() => props.href.includes('http://')
    || props.href.includes('https://')
    || props.href.includes('mailto:')
    || props.href.includes('tel:'));

/*
  Storybook
*/
const runtimeConfig = useRuntimeConfig();
const isStorybook = runtimeConfig.public.IS_STORYBOOK;

const replacedHref = computed(() => (props.href === '/home' ? '/' : props.href));
</script>
