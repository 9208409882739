/*
    Client Init Plugin
    @description: Everything in here will be resolved one time on client side
    @author: Luca Margadant <luca.margadant@nueva.ch>
*/

export default defineNuxtPlugin(async (/* nuxtApp */) => {
    if (process.client) {
        /*
            Initialize Appsize helper
        */
        useAppsize();
    }
});
