<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    class="atom-richtext"
    :class="
      [
        `is-text-size--${textSize}`,
        { 'is-on-grid': isOnGrid },
      ]"
  >
    <div
      :class="`is-text-size--${textSize}`"
      class="atom-richtext__inner"
    >
      <RichTextRenderer
        v-if="isObject(html)"
        :document="html"
      />

      <div
        v-else
        v-html="html"
      />
    </div>
  </div>
</template>

<script setup>
import { RichTextRenderer } from '@marvr/storyblok-rich-text-vue-renderer';

const props = defineProps({
    html: {
        type: [String, Object],
        required: true,
        default: null,
    },

    textColor: {
        type: String,
        default: 'var(--c-black)',
    },

    textSize: {
        type: String,
        default: 'default',
    },

    alignment: {
        type: String,
        default: 'left',
        validator: (value) => ['left', 'center', 'right'].includes(value),
    },

    isOnGrid: {
        type: Boolean,
        default: false,
    },
});

const {
    textColor,
    alignment,
} = toRefs(props);
</script>

<style lang="scss" scoped>
.atom-richtext{
    &.is-on-grid {
        @include wrapper-layout;
        @include grid-layout();

        margin-bottom: var(--m-bottom--small);
    }
}

.atom-richtext__inner {
    color: v-bind(textColor);
    font-family: var(--f-family--primary);
    font-size: var(--f-size--p);
    font-weight: var(--f-weight--400);
    line-height: var(--l-height--p);
    text-align: v-bind(alignment);

    .is-text-size--big & {
        color: var(--c-primary);
        font-size: var(--f-size--p-big);
        font-weight: var(--f-weight--900);
        line-height: var(--l-height--p-big);
    }

    .is-on-grid & {
        @include default-content-columns;
    }

    &.is-text-size--big  {
        .is-on-grid & {
            @include default-content-columns('full');
        }
    }

    ::v-deep(ul)  {
        @include fluid-simple('margin-bottom', 16px, 19px);
        display: inline-block;
        padding-left: 25px;

        li {
            position: relative;

            &:before {
                position: absolute;
                top: 14px;
                left: -15px;
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background: v-bind(textColor);
                content: '';
            }
        }
    }

    ::v-deep(strong) {
        font-weight: var(--f-weight--900);
    }

    ::v-deep(b) {
        color: var(--c-primary);
        font-weight: var(--f-weight--900);
    }

    ::v-deep(p)  {
        margin: var(--f-size--p) 0;

        b, strong {
            font-family: var(--f-family--primary);
        }

        &:last-child {
            margin: 0;
        }
    }

    ::v-deep(a) {
        color: var(--c-secondary);
        font-weight: var(--f-weight--400);
    }

    .is-text-size--big & ::v-deep(a) {
        @include fluid-simple('border-width', 4px, 6px);
        @include fluid-simple('--transform-value', 8px, 24px);
        @include fluid-simple('--padding-horizontal', 25px, 30px);
        @include fluid-simple('--padding-vertical', 12px, 15px);

        display: inline-block;
        padding: var(--padding-vertical) var(--padding-horizontal);
        border-style: solid;
        border-color: var(--c-secondary);
        border-radius: var(--b-radius--full);
        color: var(--c-secondary);
        font-size: var(--f-size--p-big-link);
        font-weight: var(--f-weight--900);
        line-height: var(--l-height--p-big-link);
        text-decoration: none;
        transform: translateY(calc(0px - var(--transform-value)));
    }
}
</style>
