/*
    How to use:
    Add in App.vue or where ever you want to have a lenis isntance:
    <lenis
      v-if="useLenis"
      @initiated="addLenis($event, 'global');" // Change global to whatever you want
    >
      <NuxtPage />
    </lenis>
*/

const instances = {};

export const getLenis = (id) => instances[id] || null;
export const addLenis = (lenis, id) => {
    instances[id] = lenis;
};
export const clearLenis = (id) => {
    if (!instances[id]) return;
    instances[id].lenis.destroy();
    delete instances[id];
};
export const startLenis = (id) => {
    if (!instances[id]) return;
    instances[id].lenis.start();
};
export const stopLenis = (id) => {
    if (!instances[id]) return;
    instances[id].lenis.stop();
};

export const scrollToLenis = (id, anchor, offset, duration) => {
    if (!instances[id]) return;
    instances[id].lenis.scrollTo(
        `#${anchor}`,
        {
            offset,
            duration,
        },
    );
};
